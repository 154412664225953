@font-face {
  font-display: swap;
}

body {
  margin: 0;
  overflow-x: hidden;
  background-color: #f8f8f8;
  font-size: 22px;
  font-family: "Montserrat";
}

@media only screen and (max-width: 369px) {
  body {
    font-size: 14px !important;
  }
}

.navbar {
  padding: 0 !important;
}

.fc {
  &-toolbar-title::first-letter {
    text-transform: uppercase;
  }

  &-event-title {
    font-size: 1.2rem;
    font-weight: 600;
    text-shadow: #000000 2px 2px 1px;
  }

  &-event-main {
    box-shadow: #000000 2px 2px 4px;
  }

  &-dayGridMonth-view {
    thead {
      background-color: #0d6efd;
      color: #ffffff;
    }

    tbody {
      background-color: #ffffff;
    }
  }
}

.animate-bounce {
  animation: bouncing 1s infinite cubic-bezier(0.65, 0.05, 0.36, 1);

  transition: opacity 0.5s ease-in-out;

  i {
    cursor: pointer;

    &:before {
      font-size: 70px;
    }
  }
}

@-moz-keyframes bouncing {
  0% {
    bottom: 0;
  }
  50% {
    bottom: 30px;
    opacity: 0.4;
  }
  100% {
    bottom: 0;
  }
}

@-webkit-keyframes bouncing {
  0% {
    bottom: 0;
  }
  50% {
    bottom: 30px;
    opacity: 0.4;
  }
  100% {
    bottom: 0;
  }
}

@keyframes bouncing {
  0% {
    bottom: 0;
  }
  50% {
    bottom: 30px;
    opacity: 0.4;
  }
  100% {
    bottom: 0;
  }
}

.row {
  --bs-gutter-x: 0 !important;
}

.text-justify {
  text-align: justify;
}

.space-nowrap {
  white-space: nowrap;
}

@media only screen and (max-width: 1199px) {
  .fc-toolbar-title {
    font-size: 1rem !important;
  }
  nav .cu-icon {
    width: 22px;
  }
  .fc-today-button, .fc-prev-button, .fc-next-button {
    padding: 2px 4px;
  }
  body {
    font-size: 17px;
  }
}

.welcomeFooter > a:hover {
  text-decoration: none !important;
}

a {
  text-decoration: none !important;
  cursor: pointer;

  &:hover {
    text-decoration: underline !important;
  }
}

.btn-price {
  &-add, &-delete {
    padding: 1px 6px;

    i:before {
      font-size: 2rem;
    }
  }
}

.drag-and-drop-price {
  cursor: move;

  i:before {
    font-size: 2rem;
  }
}


.cu- {
  &icon {
    width: fit-content;
    height: fit-content;

    &__xs {
      width: 27px;
      margin-bottom: -6px;
    }
  }

  &airport {
    content: url(assets/icons/airport.png);
  }

  &balcony {
    content: url(assets/icons/balcony.png);
  }

  &beach {
    content: url(assets/icons/beach.png);
  }

  &bedroom {
    content: url(assets/icons/bedroom.png);
  }

  &conditioner {
    content: url(assets/icons/conditioner.png);
  }

  &golf {
    content: url(assets/icons/golf.png);
  }

  &house {
    content: url(assets/icons/house.png);
  }

  &tableware {
    content: url(assets/icons/tableware.png);
  }

  &tv {
    content: url(assets/icons/tv.png);
  }

  &ua {
    content: url(assets/icons/ua.png);
  }

  &de {
    content: url(assets/icons/de.png);
  }
}