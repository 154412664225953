.paella {
  z-index: 0;
  animation: spin 4s linear infinite;
  margin: 30px 0;
  width: 300px;

  &-background {
    background-color: #ffffff;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100vh;
    width: 100vw;
  }

  &-title, &-link {
    z-index: 1;
    -webkit-transform: translateZ(1px);
    -moz-transform: translateZ(1px);
    -o-transform: translateZ(1px);
    transform: translateZ(1px);
  }

  &-link {
    font-size: 2rem;
    color: #000000;
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }
}

@keyframes spin {
  100% {
    transform: rotate(360deg);
  }
}

.loading {
  width: 205px;
  margin-right: -55px;

  &::after {
    content: "";
    animation: changeDots .5s linear infinite;
  }
}

@keyframes changeDots {
  0% {
    content: "";
  }
  33% {
    content: " .";
  }
  66% {
    content: " . .";
  }
  100% {
    content: " . . .";
  }
}